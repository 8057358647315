import { NunitoFont } from "@/src/fonts/fonts-config";

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper = ({ children }: AuthWrapperProps) => {
  return (
    <div
      className={`flex flex-col items-center min-h-dvh ${NunitoFont.className}`}
    >
      <div>
        <img className="w-full h-[65px]" src="/images/mobile-logo.png" alt="" />
      </div>
      <div className="w-full max-w-sm bg-[#f5fffb] px-5 has-[*:checked]:overflow-hidden relative h-full flex-1 flex flex-col">
        <main className="flex-grow flex flex-col pb-5">{children}</main>
      </div>
    </div>
  );
};

export { AuthWrapper };
