import React from "react";

const SingleBlogPostSkeleton = () => {
  return (
    <div className="container mx-auto px-4 py-8 lg:flex lg:gap-8">
      {/* Main Content */}
      <div className="lg:w-2/3">
        {/* Breadcrumb and Title */}
        <div className="mb-4">
          <div className="h-4 bg-gray-300 rounded w-[20%] mb-3"></div>
          <div className="h-4 bg-gray-300 rounded w-[50%]"></div>
        </div>

        {/* Image */}
        <div className="w-full h-64 bg-gray-300 rounded-lg mb-6"></div>

        {/* Article Content Skeleton */}
        <div className="space-y-4">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-5/6"></div>
          <div className="h-4 bg-gray-300 rounded w-4/6"></div>
          <div className="h-4 bg-gray-300 rounded w-3/6"></div>
        </div>

        {/* Comments Section */}
        <div className="mt-10">
          <div className="h-4 bg-gray-300 rounded w-[30%] mb-3"></div>
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
              <div className="flex-1">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                <div className="h-4 bg-gray-200 rounded w-full mt-1"></div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
              <div className="flex-1">
                <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                <div className="h-4 bg-gray-200 rounded w-full mt-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar - Similar Posts */}
      <div className="lg:w-1/3 mt-8 lg:mt-0">
        <h3 className="text-xl font-semibold mb-4">Similar Post</h3>
        <div className="space-y-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="flex space-x-4">
              <div className="w-24 h-16 bg-gray-300 rounded"></div>
              <div className="flex-1">
                <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2 mt-1"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { SingleBlogPostSkeleton };
