"use client";
import { forwardRef, useCallback } from "react";
import { HeyWowFont } from "src/fonts/fonts-config";

interface FloatingLabelTextAreaProps {
  id: string;
  name: string;
  label: string;
  value?: string;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const FloatingLabelTextArea = forwardRef<
  HTMLTextAreaElement,
  FloatingLabelTextAreaProps
>(({ id, name, label, value, error, onChange, ...rest }, ref) => {
  const handleChange = useCallback(
    (event: any) => {
      onChange?.(event);
    },
    [onChange]
  );

  return (
    <div className={`${HeyWowFont.className} relative bg-[#f5fffb]`}>
      <div className="relative">
        <textarea
          ref={ref}
          value={value}
          name={name}
          id={id}
          onChange={handleChange}
          className="px-2.5 pb-2.5 pt-3 w-full text-md text-gray-900 rounded-lg border border-1 border-[#666666] focus:outline-none focus:ring-0 focus:border-gray-400 peer bg-[#f5fffb] disabled:cursor-not-allowed disabled:bg-[#ececec] resize-none"
          placeholder=""
          rows={4}
          {...rest}
        />
        <label
          htmlFor={id}
          className="peer-disabled:bg-[#ececec] absolute text-md text-[#666666] duration-300 transform -translate-y-4 scale-75 top-2 z-5 origin-[0] px-2 peer-focus:px-3 peer-focus:text-[#666666] peer-focus:text-lg peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 bg-[#f5fffb]"
        >
          {label}
        </label>
      </div>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  );
});

FloatingLabelTextArea.displayName = "floating-label-textarea";

export { FloatingLabelTextArea };
