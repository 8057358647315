export function commafy(numb: number | string, addCurrency?: boolean) {
  const num = (Number(numb) / 100)
  if (addCurrency) {
    return `₦${num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function commafySingle(numb: number | string, addCurrency?: boolean) {
  // Convert to number and divide by 100
  const num = Number(numb) / 100;
  
  // Format large numbers to K, M, B, T
  let formattedNum;
  if (num >= 1000000000000) {
    formattedNum = (num / 1000000000000).toFixed(1) + 'T';
  } else if (num >= 1000000000) {
    formattedNum = (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    formattedNum = (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    formattedNum = (num / 1000).toFixed(1) + 'K';
  } else {
    formattedNum = num.toFixed(2);
  }
  
  // Remove trailing zeros after decimal point
  formattedNum = formattedNum.replace(/\.0+([KMBT]?)$/, '$1');
  
  // Add currency symbol if requested
  if (addCurrency) {
    return `₦${formattedNum}`;
  }
  
  return formattedNum;
}

export function splitAndCapitalize(str: string, splitBy?: RegExp | string) {
  let words = str.split(splitBy || " ");

  let capitalizedWords = words
    .map((word) => word.toLowerCase())
    .map((word, i) =>
      i == 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word
    );
  // Join the capitalized words with spaces
  return capitalizedWords.join(" ");
}
export function splitAndCapitalizeEachWord(
  str: string,
  splitBy?: RegExp | string
) {
  let words = str.split(splitBy || " ");

  let capitalizedWords = words
    .map((word) => word.toLowerCase())
    .map(
      (word, i) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  // Join the capitalized words with spaces
  return capitalizedWords.join(" ");
}

export function formatPhoneNumber(number: string) {
  // Remove all non-digit characters
  const digits = number.replace(/\D/g, "");

  let formattedNumber = "";

  // Check if the number starts with a country code (assuming it's at least 3 digits)
  // if (digits.length > 10) {
  //   // Extract country code (first 3 digits)
  //   const countryCode = digits.slice(0, 3);
  //   const part1 = digits.slice(3, 5); // First part
  //   const part2 = digits.slice(5, 9); // Second part
  //   const part3 = digits.slice(9, 13); // Third part

  //   formattedNumber = `+(${countryCode})-${part1}-${part2}-${part3}`;
  // } else {
  // Assume it's a local number and format without the country code
  const part1 = digits.slice(1, 3); // First part
  const part2 = digits.slice(3, 7); // Second part
  const part3 = digits.slice(7, 11); // Third part

  formattedNumber = `+234 ${part1}${part2}${part3}`;
  // }

  return formattedNumber;
}
