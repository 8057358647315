import React from "react";
import Link from "next/link";
import { cn } from "clsx-for-tailwind";
import { HeyWowFont } from "src/fonts/fonts-config";

interface BaseButtonProps {
  children: React.ReactNode;
  extraPadding?: string;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  variant?:
    | "danger"
    | "primary"
    | "primaryOutline"
    | "mild"
    | "grey"
    | "greyOutline"
    | "mildOutline"
    | "mildDanger"
    | "link";
  href?: string;
  className?: string;
}

const BaseButtonOutline: React.FC<BaseButtonProps> = ({
  children,
  extraPadding,
  fullWidth,
  onClick,
  type = "button",
  disabled = false,
  variant = "primary",
  href,
  className = "",
}) => {
  const baseStyles =
    "relative inline-flex items-center justify-center gap-x-1.5 rounded-md py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2";

  const variantStyles = {
    danger:
      "border border-[#CC0000] text-[#CC0000]  focus-visible:outline-red-500",
    mildDanger:
      "border border-[#CC0000] bg-white text-[#CC0000]  focus-visible:outline-gray-200",
    primary:
      "border border-[#264FFA] text-[#264FFA]  focus-visible:outline-indigo-500",
    primaryOutline:
      "border border-white text-white  focus-visible:outline-indigo-500",
    mild: "border border-white text-[#264FFA]  focus-visible:outline-gray-200",
    mildOutline:
      "border border-[#264FFA] text-[#264FFA] focus-visible:outline-gray-200",
    grey: "border border-[#A0A0A0] text-[#A0A0A0]  hover:text-white focus-visible:outline-gray-500",
    greyOutline:
      "border border-[#535050] text-[#535050] bg-white  focus-visible:outline-gray-500",
    link: "bg-transparent text-inherit  focus-visible:outline-gray-500 shadow-none hover:shadow-none",
  };

  const padding = extraPadding ?? "px-4";
  const width = fullWidth ? "w-full" : "w-auto";
  const disabledStyles = "disabled:bg-slate-400 disabled:cursor-not-allowed";

  const combinedClassName = cn(
    HeyWowFont.className,
    baseStyles,
    variantStyles[variant],
    padding,
    width,
    disabledStyles,
    className
  );

  if (href) {
    return (
      <Link href={href} className={combinedClassName}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={combinedClassName}
    >
      {children}
    </button>
  );
};

export { BaseButtonOutline };
