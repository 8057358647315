"use client";

import React from "react";
import { Container } from "../../container";
import { HeyWowFont } from "@/src/fonts/fonts-config";
import Link from "next/link";
import clsx from "clsx";
import BaseButton from "../../button/base-button";

const IWD = () => {
  return (
    <Container>
      <div
        className={` flex items-center justify-between flex-col sm:flex-row gap-8 w-full p-4 sm:p-10 py-12 mb-5 ${HeyWowFont.className}`}
      >
        <div className="basis-3/6 space-y-5">
          <h1 className="text-[#2F2F2F] text-xl md:text-2xl lg:text-3xl font-bold my-2">
            Celebrating Women, <br /> Empowering Futures with{" "}
            <span className="text-[#264FFA]"> EdGo</span>
          </h1>
          <p className="text-[#444444] mt-6">
            In recognition of the important role of women in creating brighter
            future for children, we'd cover the first fund deposit/contribution
            for any mother that signs up on EdGo in the month of March, 2025.
          </p>
          <div className="flex flex-col min-[460px]:flex-row items-start min-[460px]:items-end justify-start space-y-3 min-[460px]:space-x-3 text-white">
            <BaseButton
              className="px-6 py-3 shadow"
              href="/iwd"
              variant="primary"
            >
              See Benefits
            </BaseButton>
          </div>
        </div>
        <div className="basis-3/6 flex justify-end">
          <img className="w-auto" src="/images/iwd-banner.png" alt="" />
        </div>
      </div>
    </Container>
  );
};
export { IWD };
