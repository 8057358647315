import { HeyWowFont } from "src/fonts/fonts-config";
import React from "react";
import Link from "next/link";
import { cn } from "clsx-for-tailwind";
import Spinner from "../../button/spinner";

interface BaseButtonProps {
  children: React.ReactNode;
  extraPadding?: string;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  variant?:
    | "danger"
    | "primary"
    | "primaryOutline"
    | "mild"
    | "grey"
    | "greyOutline"
    | "mildOutline"
    | "mildDanger"
    | "link";
  href?: string;
  className?: string;
  isLoading?: boolean;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  extraPadding,
  fullWidth,
  onClick,
  type = "button",
  disabled = false,
  variant = "primary",
  href,
  className = "",
  isLoading = false,
}) => {
  const baseStyles =
    "relative inline-flex items-center justify-center gap-x-1.5 rounded-md py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2";

  const variantStyles = {
    danger:
      "bg-[#CC0000] text-white hover:bg-red-600 focus-visible:outline-red-500",
    mildDanger:
      "bg-[#fff] text-[#CC0000] hover:bg-gray-100 focus-visible:outline-gray-200",
    primary:
      "bg-[#264FFA] text-white hover:bg-indigo-400 focus-visible:outline-indigo-500",
    primaryOutline:
      "bg-transparent text-white hover:bg-[#1535b6] focus-visible:outline-indigo-500 border border-white",
    mild: "bg-[#fff] text-[#264FFA] hover:bg-gray-100 focus-visible:outline-gray-200",
    mildOutline:
      "bg-transparent text-[#264FFA] hover:bg-gray-100 border border-[#264FFA] focus-visible:outline-gray-200",
    grey: "bg-[#A0A0A0] text-white hover:bg-gray-500 focus-visible:outline-gray-500",
    greyOutline:
      "text-[#535050] bg-white hover:bg-slate-100 border border-[#535050] focus-visible:outline-gray-500",
    link: "bg-transparent text-inherit hover:bg-transparent focus-visible:outline-gray-500 shadow-none hover:shadow-none",
  };

  const padding = extraPadding ?? "px-4";
  const width = fullWidth ? "w-full" : "w-auto";
  const disabledStyles = "disabled:bg-slate-400 disabled:cursor-not-allowed";

  const combinedClassName = cn(
    HeyWowFont.className,
    baseStyles,
    variantStyles[variant],
    padding,
    width,
    disabledStyles,
    className
  );

  // const combinedClassName = clsx(
  //   `${HeyWowFont.className} ${baseStyles} ${variantStyles[variant]} ${padding} ${width} ${disabledStyles} ${className}`
  // );

  if (href) {
    return (
      <Link href={href} className={combinedClassName}>
        {children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={combinedClassName}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default BaseButton;
