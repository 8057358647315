import React from "react";

const ProfileSkeleton = () => {
  return (
    <div className="p-6 w-full mx-auto bg-gray-50 animate-pulse">
      <div className="h-8 w-32 bg-gray-300 rounded"></div>
      <div className="h-4 w-48 bg-gray-200 rounded mt-2"></div>

      <div className="bg-white p-6 rounded-lg shadow mt-4">
        <div className="flex items-center space-x-4">
          <div className="h-16 w-16 bg-gray-300 rounded-full"></div>
          <div>
            <div className="h-4 w-40 bg-gray-300 rounded"></div>
            <div className="h-3 w-32 bg-gray-200 rounded mt-1"></div>
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <div className="h-4 w-32 bg-gray-300 rounded"></div>
          <div className="grid grid-cols-3 gap-4">
            <div className="h-3 w-full bg-gray-200 rounded"></div>
            <div className="h-3 w-full bg-gray-200 rounded"></div>
            <div className="h-3 w-full bg-gray-200 rounded"></div>
          </div>

          <div className="h-4 w-32 bg-gray-300 rounded mt-4"></div>
          <div className="grid grid-cols-3 gap-4">
            <div className="h-3 w-full bg-gray-200 rounded"></div>
            <div className="h-3 w-full bg-gray-200 rounded"></div>
            <div className="h-3 w-full bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>

      <div className="h-10 w-40 bg-gray-300 rounded mt-6"></div>
    </div>
  );
};

export { ProfileSkeleton };
