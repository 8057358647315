"use client";

import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import BaseButton from "../button/base-button";
import { usePathname, useRouter } from "next/navigation";
import Link from "next/link";
import { getToken } from "@/src/utils/storage";
import { GrPhone } from "react-icons/gr";
import { GoMail } from "react-icons/go";

const navigationDesk = [
  { name: "About", href: "/about" },
  { name: "Contact Us", href: "#" },
  { name: "Request a call back", href: "/request-call-back" },
  { name: "FAQs", href: "/#faqs" },
];

const navigationMob = [
  { name: "Log in", href: "/log-in" },
  { name: "About", href: "/about" },
  { name: "FAQs", href: "#faqs" },
  // { name: "Request a call back", href: "/request-call-back" },
  { name: "Contact Us", href: "#" },
];

export function MainNav() {
  const pathname = usePathname();

  const [nav, setNav] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = getToken();
      if (token) {
        setNav(true);
      } else {
        setNav(false);
      }
    }
  }, []);

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto sm:mx-auto   max-w-7xl px-4 sm:px-6 ">
            <div className="flex h-[68px] justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black  hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center ">
                  <Link href="/">
                    <img
                      className="h-[120px] w-auto"
                      src="/svgs/logo_blue.svg"
                      alt="Your Company"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navigationDesk.map((item) => {
                    if (item.name === "Contact Us") {
                      return (
                        <div className="relative group inline-block">
                          <Link
                            key={item.name}
                            href={item.href}
                            // scroll={false}
                            className={`link ${
                              pathname === item.href
                                ? "text-[#000000] font-bold"
                                : "text-[#4c4c4c] font-medium"
                            } px-3 py-2 text-sm hover:text-[#000000] hover:font-bold" `}
                          >
                            {item.name}
                          </Link>
                          <div
                            className="
      absolute left-0 mt-2 w-72
      bg-white border border-gray-200  shadow-lg
      p-4
      opacity-0
      invisible
      group-hover:opacity-100
      group-hover:visible
      transition-opacity
      duration-200   rounded-md
    "
                          >
                            <ContactTooltip
                              email="edgo@edusko.com"
                              phone="+234 705 270 4253"
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <Link
                          key={item.name}
                          href={item.href}
                          // scroll={false}
                          className={`link ${
                            pathname === item.href
                              ? "text-[#000000] font-bold"
                              : "text-[#4c4c4c] font-medium"
                          } px-3 py-2 text-sm hover:text-[#000000] hover:font-bold" `}
                        >
                          {item.name}
                        </Link>
                      );
                    }
                  })}
                </div>
              </div>
              {!nav ? (
                <>
                  <div className="flex flex-shrink-0 space-x-2 items-center">
                    <BaseButton
                      className="hidden md:block"
                      variant="greyOutline"
                      href="/log-in"
                    >
                      Log In
                    </BaseButton>
                    <BaseButton href="/sign-up">Get started</BaseButton>
                  </div>
                </>
              ) : (
                <div className="flex flex-shrink-0 items-center">
                  <BaseButton href="/home">Dashboard</BaseButton>
                </div>
              )}
            </div>
          </div>

          <Disclosure.Panel className="md:hidden bg-slate-100">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 flex flex-col">
              {navigationMob.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={`link ${
                    pathname === item.href
                      ? "text-[#000000] font-bold"
                      : "text-[#4c4c4c] font-medium"
                  } px-3 py-2 text-sm hover:text-[#000000] hover:font-bold" `}
                  aria-current={pathname === item.href ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <ContactTooltip
                email="edgo@edusko.com"
                phone="+234 705 270 4253"
                slim
              />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

interface ContactProps {
  email: string;
  phone: string;
  slim?: boolean;
}

function ContactTooltip({ email, phone, slim = false }: ContactProps) {
  return (
    <div>
      {/* Email Info */}
      <div className={`flex items-center space-x-4 ${slim ? "" : "mb-4"}`}>
        <div className="bg-gray-100 p-3 rounded-full">
          <GoMail className="text-gray-600" size={20} />
        </div>
        <div>
          {/* <p className="text-gray-500 text-xs">Email address</p> */}
          <a href={`mailto:${email}`} className="text-[#434249] text-sm ">
            {email}
          </a>
        </div>
      </div>

      {/* Divider */}
      {/* <hr className="my-2" /> */}

      {/* Phone Info */}
      <div className="flex items-center space-x-4">
        <div className="bg-gray-100 p-3 rounded-full">
          <GrPhone className="text-gray-600" size={20} />
        </div>
        <div>
          {/* <p className="text-gray-500 text-xs">Phone number</p> */}
          <a href={`tel:${phone}`} className="text-[#434249] text-sm ">
            {phone}
          </a>
        </div>
      </div>
    </div>
  );
}
