"use client";
import { ReactNode, useRef } from "react";
import { RiCloseLargeLine } from "react-icons/ri";

interface ModalProps {
  id: string;
  title?: string;
  children: ReactNode;
  onClose?: () => void;
}

export function Modal({ id, title, children, onClose }: ModalProps) {
  const modalCheckboxRef = useRef<HTMLInputElement | null>(null);

  const handleClose = () => {
    if (modalCheckboxRef.current) {
      modalCheckboxRef.current.checked = false;
    }
    if (onClose) onClose();
  };

  return (
    <>
      <input
        type="checkbox"
        id={id}
        className="hidden peer/modal"
        ref={modalCheckboxRef}
      />
      <label
        htmlFor={id}
        className={
          "inset-0 bg-blue-200 blur-3xl peer-checked/modal:flex  peer-checked/modal:w-full peer-checked/modal:right-0 peer-checked/modal:left-0  peer-checked/modal:opacity-100 peer-checked/modal:translate-x-0 translate-x-[560px]  opacity-0  py-8 px-4 z-30 absolute w-0 md:max-w-sm overflow-hidden transition-transform delay-300 duration-300 ease-in-out"
        }
      ></label>
      <section
        className={
          "peer-checked/modal:flex peer-checked/modal:w-full peer-checked/modal:right-0 peer-checked/modal:left-0  peer-checked/modal:opacity-100 peer-checked/modal:translate-x-0 translate-x-96 opacity-0  py-8 px-3 z-30 absolute w-0 md:max-w-sm overflow-hidden transition-transform delay-300 duration-300 ease-in-out"
        }
      >
        <div
          role="alert"
          className="rounded-xl border-2 border-gray-300 bg-gradient-to-b from-[#f5fffb] from-25% to-white to-50% p-4 py-6 w-full min-h-96"
        >
          <div className="flex flex-col items-center justify-start gap-4">
            {title && (
              <h2 className="w-max-72 text-xl font-bold text-center text-[#434249]">
                {title}
              </h2>
            )}
            {children}
            <label
              //   htmlFor={id}
              className="cursor-pointer absolute top-16 right-10"
              onClick={handleClose}
            >
              <RiCloseLargeLine className="text-[#666] text-xl" />
            </label>
          </div>
        </div>
      </section>
    </>
  );
}

export default Modal;
