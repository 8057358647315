import React from "react";

const BlogCategorySkeleton = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Section Wrapper */}
      <div className="space-y-12">
        {/* Repeated Blog Sections */}
        {[1, 2].map((index) => (
          <div key={index}>
            {/* Section Title */}
            <div className="h-6 w-48 bg-gray-300 rounded animate-pulse mb-4"></div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Main Article Card */}
              <div className="md:col-span-2 space-y-4">
                <div className="w-full h-64 bg-gray-300 rounded-lg animate-pulse"></div>
                <div className="flex items-center space-x-2">
                  <div className="h-4 w-20 bg-gray-300 rounded animate-pulse"></div>
                  <div className="h-4 w-12 bg-gray-300 rounded animate-pulse"></div>
                </div>
                <div className="h-6 w-3/4 bg-gray-300 rounded animate-pulse"></div>
                <div className="h-6 w-40 bg-gray-300 rounded animate-pulse"></div>
              </div>

              {/* Sidebar (Related Articles) */}
              <div className="space-y-4">
                {[1, 2].map((item) => (
                  <div key={item} className="flex space-x-4">
                    <div className="w-28 h-20 bg-gray-300 rounded animate-pulse"></div>
                    <div className="flex-1 space-y-2">
                      <div className="h-4 w-16 bg-gray-300 rounded animate-pulse"></div>
                      <div className="h-4 w-3/4 bg-gray-300 rounded animate-pulse"></div>
                      <div className="h-4 w-20 bg-gray-300 rounded animate-pulse"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { BlogCategorySkeleton };
