import React from "react";

const PartnerDasboardSkeleton = () => {
  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      {/* Header Skeleton */}
      <div className="flex items-center justify-between mb-4">
        <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
        <div className="flex space-x-4">
          <div className="h-10 w-24 bg-gray-200 rounded-lg animate-pulse"></div>
          <div className="h-10 w-28 bg-gray-200 rounded-lg animate-pulse"></div>
        </div>
      </div>

      {/* Stats Skeleton */}
      <div className="grid grid-cols-4 gap-4 mb-6">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className="h-24 bg-gray-200 rounded-lg animate-pulse"
          ></div>
        ))}
      </div>

      {/* Referrals Section */}
      <div className="h-6 w-32 bg-gray-200 rounded animate-pulse mb-4"></div>

      {/* Table Skeleton */}
      <div className="bg-white p-4 rounded-lg shadow">
        {/* Table Header */}
        <div className="flex items-center space-x-4 mb-4">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="w-1/6 h-6 bg-gray-200 rounded animate-pulse"
            ></div>
          ))}
        </div>

        {/* Table Rows */}
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 py-3 border-b border-gray-100"
          >
            <div className="w-6 h-6 bg-gray-200 rounded animate-pulse"></div>
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="w-1/6 h-6 bg-gray-200 rounded animate-pulse"
              ></div>
            ))}
          </div>
        ))}
      </div>

      {/* Pagination Skeleton */}
      <div className="flex justify-center mt-4 space-x-2">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className="w-8 h-8 bg-gray-200 rounded animate-pulse"
          ></div>
        ))}
      </div>
    </div>
  );
};

export { PartnerDasboardSkeleton };
