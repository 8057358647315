import { jwtDecode } from "jwt-decode";
import { UseFormSetValue } from "react-hook-form";
import { savingsFormData } from "../app/(protected routes)/quick-save/create/page";


export const TOKEN = "-edusko-session-token-";
export const USER_DATA = "-edusko-session-user-";

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(TOKEN);
  }
};

export const clearToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.removeItem(TOKEN);
  }
};

export const saveUserInfoToStorage = (
  payload: Record<string, string | number | undefined>
) => {
  try {
    if (payload.token) {
      delete payload.token;
    }
    return localStorage.setItem(USER_DATA, JSON.stringify(payload));
  } catch (e) {
    return e;
  }
};

export const getUserInfoFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_DATA) as string);
  } catch (e) {
    return null;
  }
};

export const clearStorage = () => {
  try {
    localStorage.remove(TOKEN);
    localStorage.remove(USER_DATA);
  } catch (e) {
    return e;
  }
};

export const handleFormStateChange = (
  fieldName: string,
  getValues: () => void,
  setValue: (name: string, value: any) => void,
  trigger: (value: any) => void,
  updateState?: (value: any) => void
) => {
  return (val: any) => {
    // Optionally update any state passed in
    updateState && updateState(val);

    // Update the form state
    setValue(fieldName, val.value);
    trigger(fieldName);
    sessionStorage.setItem("child-profile", JSON.stringify(getValues()));
  };
};

export const isTokenValid = (token: string) => {
  if (token) {
    const decodedToken: any = jwtDecode(token);
    return Date.now() <= decodedToken?.exp * 1000;
  }
  return false;
};

export const handleParentFormChange = (
  fieldName: string,
  getValues: () => void,
  setValue: (name: string, value: any) => void,
  trigger: (value: any) => void,
  updateState?: (value: any) => void
) => {
  return (val: any) => {
    // Optionally update any state passed in
    updateState && updateState(val);

    // Update the form state
    setValue(fieldName, val.value);
    trigger(fieldName);
    sessionStorage.setItem("parent-profile", JSON.stringify(getValues()));
  };
};

export const handleQSaveFormChange = (
  fieldName: string,
  getValues: () => void,
  setValue: any,
  trigger: (value: any) => void,
  updateState?: (value: any) => void
) => {
  return (val: any) => {
    // Optionally update any state passed in
    updateState && updateState(val);

    // Update the form state
    setValue(fieldName, val.value);
    trigger(fieldName);
    sessionStorage.setItem("q-save-form", JSON.stringify(getValues()));
  };
};

export const saveUserProfileToStorage = (
  payload: Record<string, string | number | undefined> | null | undefined,
  key: string = "parent-profile-page"
): void => {
  try {
    if (!payload) {
      console.warn("Payload is null or undefined. Nothing to save.");
      return;
    }
    const serializedData = JSON.stringify(payload);

    sessionStorage.setItem(key, serializedData);
  } catch (error) {
    console.error("Failed to save user profile to storage:", error);
  }
};
