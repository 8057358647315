"use client";
import { useRouter } from "next/navigation";
import React from "react";
import { IoArrowBack, IoNotificationsOutline, IoMenu } from "react-icons/io5";
import BaseButton from "../landing-page/button/base-button";
import Link from "next/link";

interface NavbarProps {
  currentStep?: number;
  prevStep?: () => void;
}

const Navbar = ({ currentStep, prevStep }: NavbarProps) => {
  const router = useRouter();
  return (
    <div className="flex justify-between items-center px-4">
      <BaseButton
        variant="link"
        onClick={
          currentStep && currentStep > 1 ? prevStep : () => router.back()
        }
        className="flex items-center gap-2 cursor-pointer text-base text-[#666] mx-0 px-0"
      >
        <IoArrowBack color="#666 " />
        <span>Back</span>
      </BaseButton>
      <div className="flex flex-shrink-0 items-center">
        <img
          className="h-[100px] w-auto"
          src="/svgs/logo_blue.svg"
          alt="Your Company"
        />
      </div>
      <div className="flex items-center gap-6">
        {/* <Link href="notifications" className="relative cursor-pointer">
          <span className="size-4 inline-flex justify-center text-white text-xs absolute right-0 -top-2 rounded-full bg-red-600">
            3
          </span>
          <IoNotificationsOutline size={25} />
        </Link> */}
        <label htmlFor="sidebar-toggle" className="cursor-pointer">
          <IoMenu size={25} />
        </label>
      </div>
    </div>
  );
};

export { Navbar };
